<template>
  <div>
    <div v-if="isContentImgOrGif()">
      <v-img
        class="white--text"
        contain
        :src="filePath"
        :aspect-ratio="aspectRatio"
      />
    </div>
    <div v-else>
      <video
        width="100%"
        :height="setVideoHeight"
        controls
        contain
        :aspect-ratio="aspectRatio"
      >
        <source
          :src="filePath"
          type="video/mp4"
        >
      </video>
    </div>
    <v-card color="#ECEFF1">
      <br>
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-pencil
        </v-icon> Edit Banner
      </h2>
      <v-card-text class="text-body-1 text-center">
        <v-container>
          <v-col cols="12">
            <v-text-field
              v-model="name"
              label="Name*"
              hint="Name for your reference - 'ChristmasEve'.(Only alpha dashes allowed)"
              required
            />
          </v-col>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Start date"
              >
                <v-datetime-picker
                  ref="start"
                  v-model="start"
                  label="Start Date time*"
                  :text-field-props="{
                    suffix: userTimeZone,
                    errorMessages: errors,
                    class: 'custom-label-color',
                  }"
                  :date-picker-props="{
                    headerColor: '#37474F',
                    min: minStartDate
                  }"
                  :time-picker-props="{
                    headerColor: '#37474F'
                  }"
                >
                  <template v-slot:dateIcon>
                    <v-icon
                      large
                      color="#37474F"
                    >
                      mdi-calendar
                    </v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon
                      color="#37474F"
                      large
                    >
                      mdi-alarm
                    </v-icon>
                  </template>
                  <template v-slot:actions>
                    <v-btn
                      color="grey lighten-1"
                      text
                      @click.native="cancelStartDialog"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="submitStartDialog"
                    >
                      Ok
                    </v-btn>
                  </template>
                </v-datetime-picker>
              </validation-provider>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-datetime-picker
                ref="end"
                v-model="end"
                label="End Date time"
                :text-field-props="{
                  hint: 'Leave blank if never ends',
                  persistentHint: true,
                  suffix: userTimeZone
                }"
                :date-picker-props="{
                  headerColor: '#37474F',
                  min: minEndDate
                }"
                :time-picker-props="{
                  headerColor: '#37474F'
                }"
              >
                <template v-slot:dateIcon>
                  <v-icon
                    color="#37474F"
                    large
                  >
                    mdi-calendar
                  </v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon
                    color="#37474F"
                    large
                  >
                    mdi-alarm
                  </v-icon>
                </template>
                <template v-slot:actions>
                  <v-btn
                    color="red darken-1"
                    text
                    @click.native="clearEndDialog"
                  >
                    Clear
                  </v-btn>
                  <v-btn
                    color="grey lighten-1"
                    text
                    @click.native="cancelEndDialog"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="submitEndDialog"
                  >
                    Ok
                  </v-btn>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
              class="text-left"
            >
              <div v-if="contentType === 'image'">
                <v-slider
                  v-model="rotationInterval"
                  label="Rotation Interval"
                  max="30"
                  min="2"
                  color="#FF3700"
                  thumb-label
                />
              </div>
              <div v-else>
                <p> Duration: {{ contentDuration | durationConverter }} </p>
              </div>
              <p>Dimension: {{ editvalues.content.dimension | dimensionFormatter }} </p>
            </v-col>
            <v-col
              cols="12"
              class="text-left"
            >
              <v-btn
                v-if="showScheduleBtn"
                small
                dark
                class="mt-2"
                color="#FF3700"
                depressed
                default
                rounded
                @click="showAdvancedSchedule = true"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
                Advanced Schedule
              </v-btn>
              <advanced-scheduling v-model="showAdvancedSchedule" />
            </v-col>
            <v-col
              cols="12"
            >
              <advanced-scheduling-list @show-content-form="showContentForm" />
              <br>
              <advanced-schedule-edit-before-creation
                v-if="showScheduleContent"
                :editvalues="editScheduleContent"
                :scheduleindex="scheduleIndex"
                @closed="showScheduleContent = false"
              />
              <br>
              <advanced-schedule-list-for-edit
                @show-form="showForm"
                @update-content="fetchLatestBannersList"
              />
              <advanced-schedule-form-for-edit
                v-if="showEdit"
                :editvalues="editScheduleInfo"
                @closed="showEdit = false"
                @get-latest="fetchAdvancedScheduleForEdit"
                @update-content="fetchLatestBannersList"
              />
            </v-col>
          </v-row>
        </v-container>
        <p class="text-sm-left">
          *indicates required field
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="mt-2"
          color="red darken-3"
          @click="cancel()"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          class="mt-2"
          color="#FF3700"
          @click="next()"
        >
          Next
        </v-btn>
      </v-card-actions>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </div>
</template>

<script>

  import Constants from 'src/constants';
  import { cdnUrl } from 'src/app-globals';
  import moment from 'moment-timezone';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import ModuleHelper from 'src/helpers/module-helper';
  import AdvancedScheduling from '../component/schedules/AdvancedScheduling.vue';
  import AdvancedSchedulingList from '../component/schedules/AdvancedSchedulingList.vue';
  import AdvancedScheduleListForEdit from '../component/schedules/AdvancedScheduleListForEdit.vue';
  import AdvancedScheduleFormForEdit from '../component/schedules/AdvancedScheduleFormForEdit.vue';
  import AdvancedScheduleEditBeforeCreation from '../component/schedules/AdvancedScheduleEditBeforeCreation.vue';
  import global from 'src/mixins/global';

  export default {
    name: 'EditBanner',
    components: {
      'centre-spinner': spinner,
      AdvancedScheduling,
      AdvancedSchedulingList,
      AdvancedScheduleListForEdit,
      AdvancedScheduleFormForEdit,
      AdvancedScheduleEditBeforeCreation,
    },
    filters: {
      durationConverter (val) {
        return val / 1000 + ' seconds';
      },
      dimensionFormatter (val) {
        if (val === null) {
          return 'Not available';
        }

        return `${val} px`;
      },
    },
    mixins: [global],
    props: {
      editvalues: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      name: '',
      start: '',
      end: null,
      filePath: '',
      minStartDate: moment().format('YYYY-MM-DD'),
      loading: false,
      aspectRatio: 1.778, // 1920/1080
      helper: new ModuleHelper(),
      showAdvancedSchedule: false,
      rotationInterval: 4000,
      editScheduleInfo: {},
      editScheduleContent: {},
      showEdit: false,
      showScheduleContent: false,
      scheduleIndex: 0,
      bannerFormDetails: {
        bannerName: '',
        start: '',
        end: '',
        rotationInterval: 4000,
      },
    }),
    computed: {
      minEndDate () {
        return moment(this.start).format('YYYY-MM-DD');
      },
       userTimeZone () {
        var Zone = moment.tz.guess();
        var timezone = moment.tz(Zone).zoneAbbr();
        return timezone;
      },
       showScheduleBtn () {
        return (this.helper.isSubModuleExist(Constants.MODULE_BANNER, Constants.SUB_MODULE_ADVANCED_SCHEDULING) && this.permissionCheck('create-weekly-schedule'));
      },
       advancedScheduleData () {
        return this.$store.getters['schedule/getScheduleData'];
      },
      advancedScheduleDataForEdit () {
        return this.$store.getters['schedule/getAdvancedScheduleForEdit'];
      },
      contentType () {
        return this.editvalues.content.content_type;
      },
      contentDuration () {
        return this.editvalues.content.duration;
      },
      setVideoHeight () {
        if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return 400;
        } else {
          return 200;
        }
      },
    },
    async mounted () {
      this.loading = true;
      this.name = this.editvalues.name;
      this.start = this.convertStringToLocalDatetime(this.editvalues.start);
      this.end = this.editvalues.end
        ? this.convertStringToLocalDatetime(this.editvalues.end)
        : null;
      this.rotationInterval = (this.editvalues.rotation_interval) / 1000;
      this.filePath = `${cdnUrl}/${this.editvalues.content.file_path}`;
      this.$store.dispatch('schedule/clearAdvancedScheduleForEdit');
      this.$store.dispatch('schedule/clearAdvancedSchedule');
      this.permissionCheck('find-weekly-schedule') && await this.fetchAdvancedScheduleForEdit();
      this.loading = false;
    },
    methods: {
      convertStringToLocalDatetime (stringDatetime) {
        return moment(stringDatetime).local().toDate();
      },
      async fetchAdvancedScheduleForEdit () {
        if (this.editvalues.schedules.length > 0) {
          const schedules = [];
          for (const advancedSchedule of this.editvalues.schedules) {
            schedules.push(advancedSchedule.id);
          }
          for (var i = 0; i < this.editvalues.schedules.length; i++) {
            await this.$store.dispatch('schedule/fetchAdvancedScheduleForEdit', schedules[i]);
          }
        }
      },
      cancel () {
        this.$emit('closed');
        this.$store.dispatch('schedule/clearAdvancedSchedule');
        this.$store.dispatch('schedule/clearAdvancedScheduleForEdit');
      },
      showForm (val) {
        this.editScheduleInfo = this.advancedScheduleDataForEdit.find(
          schedule => schedule.id === val,
        );
        this.showEdit = true;
      },
      isContentImgOrGif () {
        if (this.editvalues.content.content_type === 'image' || this.editvalues.content.content_type === 'gif') {
          return true;
        }
        return false;
      },
      showContentForm (val) {
        this.scheduleIndex = val.index;
        this.editScheduleContent = this.advancedScheduleData.find(
          schedule => schedule.id === val.scheduleId,
        );
        this.showScheduleContent = true;
      },
      cancelStartDialog () {
        this.$refs.start.resetPicker();
        this.$refs.start.init();
      },
      submitStartDialog () {
        this.$refs.start.okHandler();
      },
      cancelEndDialog () {
        this.$refs.end.resetPicker();
        this.$refs.end.init();
      },
      submitEndDialog () {
        this.$refs.end.okHandler();
      },
      clearEndDialog () {
        this.$refs.end.clearHandler();
      },
      next () {
        if (this.start === null || this.start === '' || this.name === '') {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please enter the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.bannerFormDetails.bannerName = this.name;
        this.bannerFormDetails.start = this.start;
        this.bannerFormDetails.end = this.end;
        this.bannerFormDetails.rotationInterval = this.rotationInterval;
        setTimeout(() => {
          this.loading = false;
          this.$emit('success', this.bannerFormDetails);
        }, 2000);
      },
      fetchLatestBannersList () {
        this.$emit('fetch-banners');
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
</style>
